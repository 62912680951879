@import '../../_scss/settings';

.c-button {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  // border: 0;
  outline: none;
  background: none;

  cursor: pointer;

  padding: ($spacing-tiny + $spacing-small) ($spacing-small + $spacing-medium);
  border-radius: $tiny-radius;
  // vertical-align: middle;
  // letter-spacing: -0.05px;
  text-decoration: none;
  text-align: center;
  width: auto;

  text-transform: uppercase;
  font-family: $font-family-regular;
  // font-weight: 700;
  font-weight: normal;
  color: $color-primary;

  @include inuit-font-size($inuit-global-font-size, 1.1);

  &:focus {
    outline-offset: -2px;
    outline: 2px solid $color-primary;
  }
}

.c-button--tabbar {
  color: inherit;
  padding-bottom: 10px;
  position: relative;
  // font-size: $sizeSuperSmall;
  font-family: $font-family-light;
  text-transform: uppercase;

  &.is-active {
    // font-weight: bold;
    font-weight: normal;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: $color-primary;
    }
  }
}

.c-button--menu {
  border-radius: 0;
  text-transform: none;
  width: 100%;
  text-align: left;
  color: $color-neutral-dark;
  font-weight: normal;

  position: relative;

  padding: $spacing-small $spacing-medium;
  padding-left: $spacing-large + $spacing-small;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;

  @include inuit-font-size(13px, 1.2);

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary-10; // == button--light
  }

  &.is-active {
    & {
      background-color: inherit; // == button--light
    }
  }

  .c-icon {
    position: absolute;
    left: $spacing-medium;
    right: auto;
    top: $spacing-small;
    width: $small-icon;
    height: $small-icon;
  }

  span {
    width: 100%;
  }

  .c-accordion & span {
    width: auto;
    flex: 1 1 auto;
  }
}

.c-button--menu-header {
  font-family: $font-family-bold;
  color: $color-primary-dark;
  padding-right: $spacing-large + $spacing-small;
  padding-left: 0;

  .c-icon {
    right: $spacing-medium;
    left: auto;
  }

  @include mq($from: small) {
    padding-left: $spacing-medium;
  }
}

// Used:

.c-button--circular {
  display: flex;
  justify-content: center;
  border-radius: $circular-radius;
  border: 0;
  padding: $spacing-tiny;
  width: $spacing-large;
  height: $spacing-large;

  transition: all 0.2s ease-in-out;

  &.c-button--primary {
    use {
      fill: $color-neutral-x-light;
      stroke: $color-neutral-x-light;
    }
  }
}

.c-button--circular-large {
  width: $spacing-large + $spacing-medium;
  height: $spacing-large + $spacing-medium;

  &.c-button--primary-dark {
    box-shadow: 0 0 6px -2px currentColor;

    &.c-button--disabled,
    &.c-button--disabled:hover {
      outline: none;
      background-color: $color-primary-dark-50 !important;

      use {
        stroke: $color-primary-dark-15;
      }
    }
  }
}

.c-button--rounded {
  border-radius: $medium-radius;
  border: 1px solid transparent;
  padding: $spacing-tiny $spacing-medium;

  display: flex;
  align-items: center;
  text-transform: none;
  line-height: 1.7;
  font-weight: normal;

  .c-icon {
    margin-right: $spacing-small;
    margin-left: -$spacing-small;

    & ~ .c-icon {
      margin-right: -$spacing-small;
      margin-left: $spacing-tiny;
    }
  }
}

.c-button--half-rounded {
  // border: 1px solid transparent;
  // padding: $spacing-tiny $spacing-medium;
  // line-height: 1.7;
  border-top-left-radius: $medium-radius;
  border-bottom-left-radius: $medium-radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: $spacing-small + $spacing-tiny;
  margin-right: 1px;

  & + .c-menu__wrapper {
    display: flex;
    align-items: stretch;
    flex-direction: row;
  }

  & + &,
  & + .c-menu__wrapper > & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $medium-radius;
    border-bottom-right-radius: $medium-radius;

    margin-right: 0;
    padding-right: 0;
    padding-left: $spacing-small + $spacing-tiny;
  }
}

// States
.c-button--disabled {
  &,
  &:hover {
    cursor: not-allowed !important;
    background: $color-neutral-x-light !important;
    color: $color-primary-dark-15 !important;
    outline: 1px solid $color-primary-dark-10;

    use {
      stroke: $color-primary-dark-25;
      fill: $color-primary-dark-25;
    }
  }

  &.c-button--tiny,
  &.c-button--menu {
    &,
    &:hover {
      color: $color-primary-dark-25 !important;
      background: $transparent !important;
      outline: none;
    }
  }
}

.c-card--secondary {
  .c-button--disabled {
    &,
    &:hover {
      background: none !important;
    }
  }
}

.c-button--read-only {
  cursor: default;

  &.c-button--small {
    padding-right: $spacing-large + $spacing-tiny;
  }

  span {
    display: block;
    max-width: 100%;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }
}

// Colors
.c-button--primary {
  text-align: center;
  color: $color-neutral-x-light;
  background: $color-primary;
  font-family: $font-family-bold;

  &:focus {
    outline: 2px solid $color-primary-dark;
  }
}

.c-button--primary-dark {
  background: $color-primary-dark;
}

.c-button--primary-dark-50 {
  background: $color-primary-dark-50;
}

.c-button--secondary {
  background: $color-neutral;
  color: $color-neutral-dark;
  font-family: $font-family-bold;
}

.c-button--light {
  //   background: $color-primary-25;
  background: $color-primary-light;
  color: $color-neutral-dark;
}

.c-button--accented {
  color: $color-neutral-x-light;
  background: $color-notice;

  &.c-button--disabled {
    background: $color-neutral-x-light !important;
    color: tint($color-neutral-dark, 30%) !important;

    &:hover use {
      stroke: none;
      fill: none;
    }
  }
}

.c-button--grey {
  color: $color-neutral-dark;
  background: $color-neutral-light;
}

.c-button--white {
  color: $color-neutral-dark;
  background: $color-neutral-x-light;
}

.c-button--alert {
  color: $color-neutral-x-light;
  background: $color-error;
  font-family: $font-family-bold;

  &.c-button--tiny {
    font-family: $font-family-regular;
    background-color: $transparent;
    color: $color-error;
  }
}

.c-button--ghost {
  background: transparent;
  border: 1px solid $color-neutral;
  color: $color-neutral-dark;
  // font-family: $font-family-bold;

  .c-icon {
    margin-left: -$spacing-small;
    margin-right: $spacing-small;
    // Hack: centering bigger icon into button
    margin-top: -1px;
    margin-bottom: -1px;
  }

  &.c-button--circular {
    .c-icon {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.c-button--select {
  position: relative;
  text-transform: none;
  text-align: left;
  padding: $spacing-small;
  color: $color-primary-dark;
  width: 100%;

  .c-icon {
    margin-right: 0;
  }

  .c-button__sup-value {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 8px 0;
    text-transform: uppercase;
    width: 100%;
    color: $color-neutral-dark;
    font-family: $font-family-bold;
    // text-align: left;
    @include inuit-font-size(10px, 1.2);
  }

  &.c-button--select-with-superscript {
    padding: 19px 8px 5px;
  }
}

.c-button--benchmark {
  color: $color-primary-dark;
  position: relative;
  min-width: 200px;

  .c-icon {
    position: absolute;
    margin: 0;
    right: 8px;
  }
}

.c-button--icon-right {
  .c-icon {
    margin-left: $spacing-small;
    margin-right: 0;
  }
}

// Sizes
.c-button--small {
  padding: $spacing-small $spacing-medium;
  // padding: $spacing-small $spacing-medium $spacing-small $spacing-large;
  text-transform: none;
}

.c-button--tiny {
  // padding: $spacing-tiny;
  padding: 0;

  * + * {
    margin-left: $spacing-tiny;
  }

  &.c-button--secondary {
    font-family: $font-family-regular;
    background-color: $transparent;
  }
}

.c-button--inactive {
  opacity: 0.5;
}

.c-button--mini {
  color: $color-primary-dark;
  text-transform: capitalize;
  font-family: $font-family-light;
  padding: $spacing-mini;

  @include inuit-font-size(10px, 1);

  .c-input__label & {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-button--mini-text-default {
  text-transform: none;
}

.c-button--absolute-top-left {
  position: absolute;
  left: $spacing-medium;
  top: $spacing-medium;

  &.c-button--large-spacing {
    left: $spacing-large;
    top: $spacing-large;
  }
}

.c-button--absolute-top-right {
  position: absolute;
  right: $spacing-medium;
  top: $spacing-medium;
  z-index: 12;
}

.c-button--toggler {
  position: relative;
  display: inline-flex;
  // align-items: center;
  width: 84px;
  height: 32px;
  border-radius: $medium-radius;
  padding: 0;
  overflow: hidden;
  background: $color-primary-10;

  .c-icon {
    width: $medium-icon;
    height: $medium-icon;
  }

  use {
    stroke: $color-neutral-dark;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background: $color-primary;

      use {
        stroke: $color-neutral-light;
      }
    }
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff5;
    width: 50%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}

.c-button--toggler-benchmark {
  use {
    stroke: transparent;
    fill: $color-neutral-dark;
  }

  input[type='radio'] {
    &:checked + label {
      use {
        stroke: transparent;
        fill: $color-neutral-light;
      }
    }
  }
}

.c-button--toggletip {
  position: relative;
  padding: 0;
  margin-left: $spacing-tiny;
  display: flex;

  // &:first-of-type {
  //   margin-left: $spacing-small;
  // }

  [role='status'] {
    display: none;
    position: absolute;
    bottom: calc(100% + #{($spacing-small + $spacing-tiny)});
    right: -($spacing-small + $spacing-tiny);
    // transform: translateX(-50%);
    background-color: $color-neutral-dark;
    color: $color-neutral-x-light;
    padding: 8px;
    box-shadow: $menu-box-shadow;
    border-radius: $tiny-radius;
    text-align: left;
    text-transform: none;
    width: auto;
    white-space: nowrap;
    @include inuit-font-size(13px, 1.25);

    &.c-button--toggletip-multiline {
      min-width: 100%;
      width: 136px;
      white-space: normal;
    }

    &:after {
      content: '';
      height: ($spacing-small + $spacing-tiny);
      width: ($spacing-small + $spacing-tiny);
      border-top: ($spacing-small + $spacing-tiny) solid $color-neutral-dark;
      border-left: ($spacing-small + $spacing-tiny) solid transparent;
      border-right: ($spacing-small + $spacing-tiny) solid transparent;
      position: absolute;
      // left: 50%;
      bottom: -($spacing-small + $spacing-tiny);
      right: $spacing-medium + $spacing-small - $spacing-mini;
    }

    // TODO: shadow on arrow.
    // display: none;
    &:before {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      width: ($spacing-small + $spacing-tiny) * 2;
      height: ($spacing-small + $spacing-tiny) * 2;

      bottom: -7px;
      right: 21px;

      box-shadow: $menu-box-shadow;
      z-index: -1;
    }
  }

  > .c-button--tiny + [role='status'] {
    bottom: calc(100% + 16px);
    right: -20px;

    &.c-button--toggletip-multiline {
      width: 180px;
    }

    &.c-button--toggletip-bottom {
      bottom: auto;
      top: calc(100% + 16px);
    }
  }

  > .c-button--circular + [role='status'] {
    bottom: calc(100% + 16px);
    right: -16px;

    &.c-button--toggletip-multiline {
      width: 180px;
    }

    &.c-button--toggletip-bottom {
      bottom: auto;
      top: calc(100% + 16px);
    }
  }

  &.is-open {
    [role='status'] {
      display: block;
    }
  }

  &.is-open-on-hover {
    .c-button:hover {
      & + [role='status'] {
        display: block;

        &::after,
        &::before {
          right: 20px;
        }
      }
    }
  }

  .c-button--toggletip-bottom {
    bottom: auto;
    top: calc(100% + #{($spacing-small + $spacing-tiny)});

    &:after {
      bottom: auto;
      top: -($spacing-small + $spacing-tiny);
      transform: rotate(180deg);
    }
    &:before {
      bottom: auto;
      top: -7px;
    }
  }
}

.c-button--sidebar-trigger {
  display: block;
  padding: $spacing-small $spacing-medium;
  margin-left: 0;

  @include mq($from: small) {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    padding: $spacing-medium;
    margin-left: -$spacing-small;
    display: none;
  }
}

.c-button--card {
  padding: $spacing-medium;

  &:hover,
  &:active,
  &:focus {
    .c-card--in-button {
      background-color: $color-primary-dark;
    }
  }
}

.c-button--breadcrumb {
  background-color: $color-primary;
  margin-right: $spacing-medium;
  border: 1px solid transparent;
  border-left-color: $color-primary;
  border-top-color: $color-primary;
  border-bottom-color: $color-primary;
  padding: 0;
  margin-right: 0;
  color: $color-neutral-x-light;
  padding: 9px $spacing-small 9px 10px;
  border-radius: 0;
  text-transform: none;
  font-family: $font-family-bold;
  text-decoration: none;

  &:after {
    content: '';
    width: $spacing-small;
    position: absolute;
    background: transparent;
    border-top: $spacing-medium solid transparent;
    border-left: 10px solid $color-primary;
    border-bottom: $spacing-medium solid transparent;
    height: 100%;
    right: -10px;
  }
}

.c-button__group {
  display: flex;

  .c-button {
    white-space: nowrap;
  }

  button:not(:first-child):not(.c-button--menu):not(.c-menu__wrapper):not(
      .c-tooltip__info
    ),
  .c-button:not(:first-of-type):not(.c-button--menu) {
    margin-left: $spacing-small;
  }

  .c-menu__wrapper {
    margin-left: 0;
  }

  &.o-flex--wrap {
    .c-button {
      margin: $spacing-tiny $spacing-small $spacing-tiny 0;
    }
  }
}

.c-button__group-vertical-center {
  align-items: center;
}

.c-button__group-fixed-bottom {
  position: fixed;
  bottom: 16px;
  right: 16px;

  //   @include mq($from: medium) {
  //     display: none;
  //   }
}

.c-button__group-half-button {
  max-width: 100%;

  .c-button,
  .c-button:not(:first-of-type) {
    margin-left: 0 !important;
  }
}

.c-button__group-half-button-no-space {
  .c-button--ellipsed {
    text-align: left;
    display: inline-block;
  }

  > .c-button--half-rounded:first-child {
    border-right: 0;
    margin-right: 0;
    max-width: calc(100% - 28px);
    padding-right: 8px;
    flex: 1;

    > .c-icon {
      flex: 0 0 24px;
      margin-right: 4px;
    }
  }

  > .c-button--half-rounded.c-button--disabled:first-child {
    outline: 0;
    border: 1px solid;
    border-right: 0;
    background: transparent !important;
  }

  .c-button--half-rounded ~ .c-button--half-rounded {
    max-width: 32px;
  }

  .c-menu__wrapper {
    position: static;

    .c-menu--open {
      left: 0;
      right: 0;
      width: auto;
    }
  }

  > .c-menu__wrapper > .c-button--disabled {
    outline: 0;
    border: 1px solid;
    background: transparent !important;

    use {
      stroke: transparent;
    }
  }

  &:focus-within {
    position: relative;
    border-radius: 20px;
    filter: drop-shadow(0 0 2px #ccc);
    background: white;
  }
}

.c-button__group-full-width {
  width: 90%;
  justify-content: center;
}

.c-button--ellipsed {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block;
}

.c-button--icon-expandable {
  position: absolute;
  overflow: hidden;

  padding: 0;
  width: 120px;
  height: 34px;

  left: auto;
  bottom: 0;

  z-index: 10;
  transition: width 0.2s ease-in-out;

  .c-button__label {
    position: absolute;
    left: $spacing-large;
  }

  @container tabs-container (inline-size < 518px) {
    width: 34px;

    &:hover,
    &:focus,
    &:active {
      width: 120px;
      box-shadow: $dragging-shadow;
    }
  }

  .c-panel__title-buttons-group & {
    padding: 0 12px;
    width: auto;
    max-width: 100%;
    height: 100%;
    margin-left: auto;
    position: relative;

    > .c-icon:first-child {
      margin: auto;
      flex: 0 0 16px;
      margin-left: -5px;
    }

    .c-button__label {
      position: relative;
      left: 8px;
      white-space: nowrap;
      margin-right: 8px;
    }
  }
}

.r-button--disposable-without-space {
  @container tabs-container (inline-size < 422px) {
    display: none;
  }
}

.c-button-expandable {
  position: absolute;
  height: 32px;
  transition: width 0.2s ease-in-out;

  &:nth-last-child(2) {
    right: 36px;
  }

  .c-menu__wrapper.c-panel__title-button &:nth-last-child(2) {
    right: 0;
  }

  .c-menu__wrapper.c-panel__title-button[aria-expanded='true'] & {
    width: auto !important;
  }

  @container panel-container (inline-size < 487px) {
    .c-button--icon-expandable:hover,
    .c-button--icon-expandable:focus {
      box-shadow: 0 0 8px -3px $color-neutral-x-dark;
    }
  }

  @container panel-container (inline-size > 486px) {
    width: auto !important;
    position: static;
  }
}

// Button Visible on Hover
.c-button--hover {
  opacity: 0;
  transition: $global-transition-fast;
}

.c-table--report-tab:has(.c-button--hover) {
  .c-button--hover {
    flex: 0 0 auto;
    margin-left: auto;
    z-index: 2;
  }

  &:focus-within,
  &:hover {
    .c-button--hover {
      opacity: 1;
      background-color: $color-neutral-x-light !important;
    }
  }

  .c-button--hovered {
    opacity: 1;
  }

  @media (hover: none) {
    .c-button--hover {
      opacity: 1;
    }
  }

  @include mq($until: small) {
    .c-button--hover {
      right: 8px;
      top: 6px;
    }
  }
}
